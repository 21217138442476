import { defineStore } from 'pinia'
import type { ContactInfo } from '../types'
import nuxtStorage from 'nuxt-storage';

export const useContactInfoStore = defineStore('contact-info', {

  state: () => ({
    contactInfo: {} as ContactInfo,
    agentId: '' as string,
    companyId: '' as string
  }),
  persist: true,
  actions: {
    setContactInfoValue (value: ContactInfo) {
      this.contactInfo = value
    },
    setContactInfoId (value: string) {
      this.contactInfo.id = value
    },
    setAgentId (value: string) {
      this.agentId = value
    },
    setCompanyId (value: string) {
      this.companyId = value
    },
    reset() {
      this.contactInfo = {} as ContactInfo
      this.agentId = ''
      this.companyId = ''
      nuxtStorage?.sessionStorage?.removeItem('contact-info');
    },
  },
  getters: {
    id: state => state.contactInfo.id,
    email: state => state.contactInfo.properties.email,
    firstname: state => state.contactInfo.properties.firstname,
    lastname: state => state.contactInfo.properties.lastname,
    suffix: state => state.contactInfo.properties.suffix,
    last_step_in_assurity: state => state.contactInfo.properties.last_step_in_assurity,
    phone: state => state.contactInfo.properties.phone,
    age: state => state.contactInfo.properties.age,
    state: state => state.contactInfo.properties.state
  }
})
